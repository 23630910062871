$(document).ready(function() {

    @@include('partials/partials.js')
    
    $(function() {
      $(".icon-menu").click(function() {
        $(".menu").slideToggle(300);
        $(this).toggleClass("active"); return false;
      });
    });

});